import React, { useState, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Box,
  Chip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  actionRow: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  centeredRow: {
    justifyContent: 'center',
    // display: 'flex',
    // alignItems: 'center'
  }
}))

const JobsiteSprayLogs = ({ jobsites, url }) => {
  const classes = useStyles()
  // State management
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({
    hasSprayLogs: 'all',
    dateRange: 'all',
  });
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [activeSite, setActiveSite] = useState({})
  const [loading, setLoading] = useState(false)

  // Sorting function
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Filter and sort data
  const filteredAndSortedData = useMemo(() => {
    return jobsites
      .filter(jobsite => {
        const matchesSearch = 
          jobsite.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          jobsite.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          jobsite.communityName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          jobsite.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          jobsite.city?.toLowerCase().includes(searchTerm.toLowerCase());
        
        const matchesHasLogs = 
          filters.hasSprayLogs === 'all' ||
          (filters.hasSprayLogs === 'yes' && jobsite.sprayLogs?.length > 0) ||
          (filters.hasSprayLogs === 'no' && (!jobsite.sprayLogs || jobsite.sprayLogs.length === 0));
        
        return matchesSearch && matchesHasLogs;
      })
      .sort((a, b) => {
        const isAsc = order === 'asc';
        
        // Helper function to handle null/undefined values
        const compareValues = (val1, val2) => {
          // Handle null/undefined values
          if (!val1 && !val2) return 0;
          if (!val1) return isAsc ? -1 : 1;
          if (!val2) return isAsc ? 1 : -1;
          
          // Compare strings case-insensitively
          if (typeof val1 === 'string' && typeof val2 === 'string') {
            return isAsc 
              ? val1.toLowerCase().localeCompare(val2.toLowerCase())
              : val2.toLowerCase().localeCompare(val1.toLowerCase());
          }
          
          // Compare numbers
          return isAsc ? val1 - val2 : val2 - val1;
        };

        switch (orderBy) {
          case 'communityName':
            return compareValues(a.communityName, b.communityName);
          case 'clientName':
            return compareValues(a.clientName, b.clientName);
          case 'name':
            return compareValues(a.name, b.name);
          case 'buildingNumber':
            // Handle building numbers that might be strings or numbers
            return compareValues(
              typeof a.buildingNumber === 'string' ? a.buildingNumber : String(a.buildingNumber),
              typeof b.buildingNumber === 'string' ? b.buildingNumber : String(b.buildingNumber)
            );
          case 'city':
            return compareValues(a.city, b.city);
          case 'sprayLogs':
            return compareValues(a.sprayLogs?.length || 0, b.sprayLogs?.length || 0);
          default:
            return 0;
        }
      });
  }, [jobsites, searchTerm, filters, order, orderBy]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMeasureSite = (site) => {
    let url = `/clientmapview/${site?._id}`
    window.open(url, '_blank').focus()
  }

  const fetchSiteDetails = async (jobSiteId) => {
    let serverURL = `${url}/api/hmi/genericGetRequest?id=${jobSiteId}&type=SPRAY_DETAILS`
    console.log('Fetch site details', serverURL)
    axios.get(serverURL).then((response, status, data) => {
      setLoading(false)
      console.log('141', response)
      // console.log(data)
      let activeSiteDetails
      if (response?.data?.data?.siteDetails) {
        activeSiteDetails = response.data.data.siteDetails
      }
      if (response?.data?.data?.sprayLogs?.length) {
        activeSiteDetails.sprayLogs = response.data.data.sprayLogs
      }
      // console.log('status', status)
      setActiveSite(activeSiteDetails)
      // if (response?.data?.length) setActiveSite(response.data)
    }).catch(err => {
      console.log('ERROR 142::', err)
    })
  }

  const handleViewDetails = (jobsite) => {
    setSelectedJobsite(jobsite);
    // fetchSiteDetails(jobsite?._id)
    console.log('This is our guy!!', jobsite)
    setDetailsOpen(true);
  };

  // Filter drawer content
  const FilterDrawer = () => (
    <Drawer 
      anchor="right" 
      open={filterDrawerOpen} 
      onClose={() => setFilterDrawerOpen(false)}
    >
      <Box width={250} p={2}>
        <Typography variant="h6">Filters</Typography>
        <List>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>Spray Logs</InputLabel>
              <Select
                value={filters.hasSprayLogs}
                onChange={(e) => setFilters({...filters, hasSprayLogs: e.target.value})}
              >
                <MenuItem value="all">All Sites</MenuItem>
                <MenuItem value="yes">With Spray Logs</MenuItem>
                <MenuItem value="no">Without Spray Logs</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>Date Range</InputLabel>
              <Select
                value={filters.dateRange}
                onChange={(e) => setFilters({...filters, dateRange: e.target.value})}
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="week">Past Week</MenuItem>
                <MenuItem value="month">Past Month</MenuItem>
                <MenuItem value="year">Past Year</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
    // console.log('Active Site...', activeSite)
    // console.log('Selected jobsite', selectedJobsite)
  // Details dialog content
  const DetailsDialog = () => {
    // console.log('Details dialog...', selectedJobsite)
    return (
    <Dialog 
      open={detailsOpen} 
      onClose={() => setDetailsOpen(false)}
      maxWidth="md"
      fullWidth
    >
      {selectedJobsite && (
        <>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{selectedJobsite.name}</Typography>
              <IconButton onClick={() => setDetailsOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              {selectedJobsite.address}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Last Spray: {selectedJobsite?.lastHerbicideApplication || ''}
            </Typography>
            <Box my={3}>
              <Typography variant="h6" gutterBottom>Spray Logs</Typography>
              {selectedJobsite.sprayLogs?.length > 0 ? (
                selectedJobsite.sprayLogs.map((log) => {
                  // console.log('Log to display', log)
                  
                  return (
                  <Card key={log._id} className="mb-3">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle2">Date & Time</Typography>
                          <Typography variant="body2">
                            {new Date(log.date).toLocaleDateString()} ({log.startTime} - {log.stopTime})
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle2">Technician</Typography>
                          <Typography variant="body2">
                            {log.techName} (License: {log.licenseNum})
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">Environmental Conditions</Typography>
                          <Typography variant="body2">
                            Wind: {log.windSpeed} {log.windDir} • Temperature: {log.temp}
                          </Typography>
                        </Grid>
                        {log.chemicalDetails?.length > 0 && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Chemicals Used</Typography>
                            <Box mt={1}>
                              {log.chemicalDetails.map((chemical, index) => {
                                // console.log('Chemical', chemical)
                                
                                return (
                                <Chip
                                  key={index}
                                  label={`${chemical.amount}${chemical.unit} ${chemical?.name} - ${chemical.concentration}%`}
                                  size="small"
                                  className="mr-1 mb-1"
                                />
                              )})}
                            </Box>
                          </Grid>
                        )}
                        {log.pdfUrl && (
                          <Grid item xs={12}>
                            <Button
                              startIcon={<PictureAsPdfIcon />}
                              variant="outlined"
                              color="primary"
                              onClick={() => window.open(log.pdfUrl, '_blank')}
                            >
                              View Documentation
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                )})
              ) : (
                <Typography color="textSecondary">
                  No spray logs available for this jobsite.
                </Typography>
              )}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  )};

  return (
    <div className="p-4">
      {/* Header with search and filters */}
      <Box mb={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search jobsites..."
              InputProps={{
                startAdornment: <SearchIcon color="action" />
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={() => setFilterDrawerOpen(true)}
            >
              Filters
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Main table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
             
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'communityName'}
                  direction={orderBy === 'communityName' ? order : 'asc'}
                  onClick={() => handleRequestSort('communityName')}
                >
                  Community Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'clientName'}
                  direction={orderBy === 'clientName' ? order : 'asc'}
                  onClick={() => handleRequestSort('clientName')}
                >
                  Customer Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Site Name
                </TableSortLabel>
              </TableCell>

              <TableCell>Address</TableCell>
             
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'buildingNumber'}
                  direction={orderBy === 'buildingNumber' ? order : 'asc'}
                  onClick={() => handleRequestSort('buildingNumber')}
                >
                  Building Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'city'}
                  direction={orderBy === 'city' ? order : 'asc'}
                  onClick={() => handleRequestSort('city')}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={orderBy === 'sprayLogs'}
                  direction={orderBy === 'sprayLogs' ? order : 'asc'}
                  onClick={() => handleRequestSort('sprayLogs')}
                >
                  Spray Logs
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={orderBy === 'areas'}
                  direction={orderBy === 'areas' ? order : 'asc'}
                  onClick={() => handleRequestSort('areas')}
                >
                  Site Areas
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((jobsite) => (
                <TableRow key={jobsite._id}>
                   <TableCell>{jobsite?.communityName || ''}</TableCell>
                   <TableCell>{jobsite?.clientName}</TableCell>
                  <TableCell>{jobsite.name}</TableCell>
                  <TableCell>{jobsite.address}</TableCell>
                  <TableCell>{jobsite?.buildingNumber || ''}</TableCell>
                  <TableCell>{jobsite.city}</TableCell>
                  <TableCell className={classes.centeredRow}>
                    <Chip 
                      label={jobsite.sprayLogs?.length || 0}
                      color={jobsite.sprayLogs?.length ? "primary" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell className={classes.centeredRow}>
                    <Chip 
                      label={jobsite.areas?.length || 0}
                      color={jobsite.areas?.length ? "primary" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell >
                    <div className={classes.actionRow}>
                      <IconButton
                        size="small"
                        onClick={() => handleViewDetails(jobsite)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleMeasureSite(jobsite)}
                      >
                        <EditLocationIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={filteredAndSortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <FilterDrawer />
      <DetailsDialog />
    </div>
  );
};

export default JobsiteSprayLogs;
