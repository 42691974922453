import React, { useState, useEffect } from 'react';
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  ButtonGroup,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  makeStyles,
  Slider
} from '@material-ui/core';

import {
  MoreVert as MoreIcon,
  Description as FileIcon,
  Print as PrintIcon,
  MonetizationOn as TaxIcon,
  CheckBox as ChecklistIcon,
  BugReport as IssuesIcon,
  PhotoCamera as CameraIcon,
  DateRange as VisitsIcon,
  CloudDone as CompleteIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ShoppingCart as ExportIcon,
  Visibility as ViewIcon
} from '@material-ui/icons';
import WorkorderDetailsDashboard from './WorkorderDetails';
import StatusCell from './PercentCompleteSlider'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: 600,
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  completeChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  inProgressChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  sliderContainer: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  progressSlider: {
    height: 4,
    padding: '0',
    '& .MuiSlider-thumb': {
      height: 8,
      width: 8,
      marginTop: -2,
      marginLeft: -4,
    },
    '& .MuiSlider-track': {
      height: 4,
    },
    '& .MuiSlider-rail': {
      height: 4,
    }
  },
  statusCell: {
    minWidth: 120,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
}));

const WorkorderList = ({ 
  workorders, 
  onDeleteWorkorder, 
  onUpdateWorkorder,
  onPrintWorkorder,
  onCompletionChange,
  onViewPunchlist,
  onEditTaxes,
  onViewIssues,
  onViewVisits,
  onExportLineItems,
  client,
  addService,
  activeWorkorder,
  onWorkorderChange,
  punchlistItems
}) => {
  const classes = useStyles();
  const [selectedWorkorder, setSelectedWorkorder] = React.useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  // const [activeWorkorder, setActiveWorkorder] = React.useState(null);
  const [editedWorkorder, setEditedWorkorder] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [menuWorkorder, setMenuWorkorder] = React.useState(null);

  useEffect(() => {
    if (selectedWorkorder) {
      setEditedWorkorder(selectedWorkorder);
    }
  }, [selectedWorkorder]);
  
  useEffect(() => {
    if (editedWorkorder && selectedWorkorder) {
      const hasUnsavedChanges = JSON.stringify(selectedWorkorder) !== JSON.stringify(editedWorkorder);
      setHasChanges(hasUnsavedChanges);
    }
  }, [editedWorkorder, selectedWorkorder]);


  React.useEffect(() => {
    if (activeWorkorder && (activeWorkorder.number === 'TBD' || activeWorkorder._id)) {
      setSelectedWorkorder(activeWorkorder);
      setDetailsDialogOpen(true);
    }
  }, [activeWorkorder]);

  const handleSaveWorkorder = (updatedWorkorder) => {
    onWorkorderChange(updatedWorkorder);
    setDetailsDialogOpen(false);
    setSelectedWorkorder(null);
  };

  const handleViewDetails = (workorder) => {
    setSelectedWorkorder(workorder);
    setDetailsDialogOpen(true);
  };

  const handleMenuClick = (event, workorder) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuWorkorder(workorder);
    setSelectedWorkorder(workorder);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuWorkorder(null);
    setSelectedWorkorder(null);
  };

  const renderActionButtons = (workorder) => {
    const isCompleted = workorder.completedDate;
    const isInvoiced = workorder.invoiceDate;
    const invoicingDisabled = client?.requiresWorkorder && !workorder.clientApprovedWorkorderNumber;
    
    return (
      <div className={classes.actionButtons}>
        {/* View Details Button */}
        <Tooltip title="View Details">
          <IconButton
            size="small"
            onClick={() => handleViewDetails(workorder)}
          >
            <ViewIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {/* More Actions Menu */}
        <Tooltip title="More Actions">
          <IconButton
            size="small"
            onClick={(e) => handleMenuClick(e, workorder)}
          >
            <MoreIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {/* Action Menu */}
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl) && Boolean(menuWorkorder)}
          onClose={handleMenuClose}
        >


          {workorder.number !== 'TBD' && (
            <MenuItem onClick={() => {
              handleMenuClose();
              onPrintWorkorder(workorder);
            }}>
              <ListItemIcon>
                <PrintIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Print Workorder" />
            </MenuItem>
          )}

          <MenuItem onClick={() => {
            handleMenuClose();
            onEditTaxes(workorder);
          }}>
            <ListItemIcon>
              <TaxIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Taxes" />
          </MenuItem>

          <MenuItem onClick={() => {
            console.log('View the punchlist 234:::', workorder, selectedWorkorder, activeWorkorder)
            handleMenuClose();
            onViewPunchlist(selectedWorkorder);
          }}>
            <ListItemIcon>
              <ChecklistIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Punchlist" />
          </MenuItem>

          <MenuItem onClick={() => {
            handleMenuClose();
            onExportLineItems(workorder);
          }}>
            <ListItemIcon>
              <ExportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Export Line Items" />
          </MenuItem>

          <MenuItem onClick={() => {
            handleMenuClose();
            onViewIssues(workorder);
          }}>
            <ListItemIcon>
              <IssuesIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Issues" />
          </MenuItem>

          <MenuItem onClick={() => {
            handleMenuClose();
            handleViewDetails({ ...workorder, activeTab: 'photos' });
          }}>
            <ListItemIcon>
              <CameraIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Photos" />
          </MenuItem>

          <MenuItem onClick={() => {
            handleMenuClose();
            onViewVisits(workorder);
          }}>
            <ListItemIcon>
              <VisitsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Service Visits" />
          </MenuItem>

          {!isCompleted && !isInvoiced && (
            <MenuItem 
              onClick={() => {
                handleMenuClose();
                onCompletionChange(workorder, 'complete');
              }}
              disabled={invoicingDisabled}
            >
              <ListItemIcon>
                <CompleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Complete & Invoice" />
            </MenuItem>
          )}

          <Divider />
          
          <MenuItem 
            onClick={() => {
              handleMenuClose();
              onDeleteWorkorder(workorder);
            }}
            style={{ color: '#f44336' }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" style={{ color: '#f44336' }} />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        </Menu>
      </div>
    );
  };

  // Handler for printing workorder
  const handlePrintWorkorder = (workorder) => {
    console.log('Printing workorder:', workorder);
    onPrintWorkorder(workorder);
  };

  // Handler for editing taxes
  const handleEditTaxes = (workorder) => {
    console.log('Opening tax editor for workorder:', workorder);
    onEditTaxes(workorder);
  };

  // Handler for viewing punchlist
  const handleViewPunchlist = (workorder) => {
    console.log('Opening punchlist for workorder:', workorder);
    onViewPunchlist(workorder);
  };

  // Handler for viewing photos
  const handleViewPhotos = (workorder) => {
    console.log('Opening photos for workorder:', workorder);
    // Implement your photo viewing logic here
  };

  // Handler for viewing service visits
  const handleViewVisits = (workorder) => {
    console.log('Opening service visits for workorder:', workorder);
    onViewVisits(workorder);
  };

  // Handler for completing and invoicing workorder
  const handleComplete = (workorder) => {
    console.log('Completing workorder:', workorder);
    onCompletionChange(workorder, 'complete');
  };

  // Handler for exporting supplies
  const handleExportSupplies = (workorder) => {
    console.log('Exporting supplies for workorder:', workorder);
    onExportLineItems(workorder);
  };

  // Handler for closing the details dialog
  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedWorkorder(null);
  };

  const handleAddServiceClick = () => {
    addService()
    setDetailsDialogOpen(true)
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Workorders ({workorders.length})</Typography>
        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddServiceClick}
        >
          Add Service
        </Button> */}
      </div>
      
      <div className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Service Type</TableCell>
              <TableCell>Crew</TableCell>
              <TableCell>Scheduled Date</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workorders.map((workorder, index) => {
              let punchlistItemsForThisWO = punchlistItems.filter(item => (item.workorder === workorder._id))
              // console.log('Pucnhilst item', punchlistItemsForThisWO)
              /// Define percentage by the number of punchlistitems that havea status of complete or .complete = true
              let percentComplete = 0
              if (punchlistItemsForThisWO.length) {
                let numComplete = punchlistItemsForThisWO.filter(item => ((item?.status === 'Complete' || item.complete)))
                // console.log('We have %d punchlist cmplete out of %d ', numComplete.length, punchlistItemsForThisWO.length)
                let pct = Math.round(Number(numComplete.length) / Number(punchlistItemsForThisWO.length) * 100)
                // console.log('Percent', pct)
                percentComplete = pct
              }
              if (workorder?.status === 'Complete' || workorder?.completedDate) percentComplete = 100
              return (
              <TableRow key={index}>
                <TableCell>{workorder.number}</TableCell>
                <TableCell>{workorder.serviceName}</TableCell>
                <TableCell>{workorder.crewName}</TableCell>
                <TableCell>{workorder.scheduledDate ? new Date(workorder.scheduledDate).toLocaleDateString({ month: 'short', year: 'short' }) : ''}</TableCell>
                <TableCell>${workorder.price}</TableCell>
                <TableCell className={classes.statusCell}>
                  {/* <Chip
                    label={workorder.completedDate ? 'Completed' : 'In Progress'}
                    className={`${classes.chip} ${
                      workorder.completedDate ? classes.completeChip : classes.inProgressChip
                    }`}
                  /> */}
                  <div className={classes.sliderContainer}>
                    <StatusCell
                      workorder={workorder}
                      percentComplete={percentComplete || 0}
                      className={classes.progressSlider}
                      disabled
                      valueLabelDisplay="off"
                    />
                  </div>
                </TableCell>
                <TableCell>{renderActionButtons(workorder)}</TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </div>

      <Dialog 
        open={detailsDialogOpen} 
        onClose={() => setDetailsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Workorder Details</DialogTitle>
        <DialogContent>
          {selectedWorkorder && (
            <WorkorderDetailsDashboard
              workorder={selectedWorkorder}
              editedData={editedWorkorder}
              onChange={setEditedWorkorder}
              onPrint={handlePrintWorkorder}
              onEditTaxes={handleEditTaxes}
              onViewPunchlist={handleViewPunchlist}
              onViewPhotos={handleViewPhotos}
              onViewVisits={handleViewVisits}
              onComplete={handleComplete}
              onExportSupplies={handleExportSupplies}
              punchlistItems={punchlistItems}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsDialogOpen(false)}>
            Close
          </Button>
          {hasChanges && (
            <Button 
              color="primary" 
              variant="contained" 
              onClick={() => {
                handleSaveWorkorder(editedWorkorder);
                setDetailsDialogOpen(false);
              }}
            >
              Save Changes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default WorkorderList;
