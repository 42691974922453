import React, { useState, useEffect } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  Divider,
  IconButton,
  Box,
  Button,
  CircularProgress,
  Paper
} from '@material-ui/core';
import JobsiteSprayLogs from './JobsiteSprayLogs';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  fuelTankHeader: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: '#aaa'
  },
  fuelTankData: {
    color: '#888',
    fontSize: '.9em'
  },
  historicalFuelDataLine: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  historicalFuelDataLineHeader: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: '#aaa'
  },
  fuelTankData: {
    color: '#888',
    fontSize: '.9em'
  },
}))

const jobsites = [
  {
    _id: '1',
    bizName: 'Business Name',
    address: '123 Main St',
    sprayLogs: [
      {
        _id: 'log1',
        date: '2024-01-01',
        techName: 'John Doe',
        licenseNum: 'ABC123',
        startTime: '09:00',
        stopTime: '10:30',
        windSpeed: '5 mph',
        windDir: 'NW',
        temp: '72°F',
        chemicalDetails: ['Chemical A', 'Chemical B'],
        pdfUrl: 'path/to/pdf'
      }
    ]
  }
  // ... more jobsites
];

const SprayLogDashboard = (props) => {
  const classes = useStyles();
  const [mtcSites, setMtcSites] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchAllMtcSites = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/hmi/genericGetRequest?type=SPRAY_DASHBOARD_DETAILS`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && response.data.data) {
        console.log('Spray Log sites', response.data)
          if (isMounted && response.data && response.data.data) {
            setMtcSites(response.data.data)
          }
        }
      })
    }
    fetchAllMtcSites()

    return () => {
      isMounted = false
    }
  }, [])
  // console.log('Jobsites', mtcSites)
  return (
    <div className={classes.root}>
    <Paper className={classes.paper}>
      <h1> Spray Log Dashboard</h1>
     
      <div></div>
      {
        loading ?  <CircularProgress size={64} /> :  <JobsiteSprayLogs jobsites={mtcSites} url={props.url} />
      }
     
    </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(SprayLogDashboard)
