import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const handleFocus = (event) => event.target.select();

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 440,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover
  },
  quantityCell: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  quantityField: {
    width: 80
  },
  typeSelect: {
    width: 100,
    marginRight: theme.spacing(1)
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  remainingQuantity: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5)
  }
}));

const ProposalLineItems = ({ lineItems = [], onCreateWorkorder }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [quantityTypes, setQuantityTypes] = useState({});

  // Helper function to get remaining quantity, defaulting to original qty if not set
  const getRemainingQty = (item) => {
    return typeof item.remainingQty === 'number' ? item.remainingQty : parseFloat(item.qty);
  };

  // Calculate progress percentage for an item
  const getProgressPercentage = (item) => {
    const remainingQty = getRemainingQty(item);
    const originalQty = parseFloat(item.qty);
    return ((originalQty - remainingQty) / originalQty) * 100;
  };

  // Modified handleQuantityChange to respect remaining quantities
  const handleQuantityChange = (id, value) => {
    const item = lineItems.find(item => item._id === id);
    let newValue = value;
    
    if (quantityTypes[id] === 'percentage') {
      newValue = Math.min(100, Math.max(0, value));
    } else {
      newValue = Math.min(getRemainingQty(item), Math.max(0, value));
    }

    setQuantities({
      ...quantities,
      [id]: newValue
    });
  };

  // Modified handleQuantityTypeChange to consider remaining quantities
  const handleQuantityTypeChange = (id, type) => {
    const item = lineItems.find(item => item._id === id);
    
    setQuantityTypes({
      ...quantityTypes,
      [id]: type
    });
    
    setQuantities({
      ...quantities,
      [id]: type === 'percentage' ? 100 : getRemainingQty(item)
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const availableIds = lineItems
        .filter(item => getRemainingQty(item) > 0)
        .map(item => item._id);
      
      setSelected(availableIds);
      const newQuantities = {};
      const newTypes = {};
      availableIds.forEach(id => {
        const item = lineItems.find(item => item._id === id);
        newQuantities[id] = getRemainingQty(item);
        newTypes[id] = 'quantity';
      });
      setQuantities(newQuantities);
      setQuantityTypes(newTypes);
    } else {
      setSelected([]);
      setQuantities({});
      setQuantityTypes({});
    }
  };

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newQuantities = { ...quantities };
    let newTypes = { ...quantityTypes };

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
      const item = lineItems.find(item => item._id === id);
      newQuantities[id] = getRemainingQty(item);
      newTypes[id] = 'quantity';
    } else {
      newSelected = selected.filter(itemId => itemId !== id);
      delete newQuantities[id];
      delete newTypes[id];
    }

    setSelected(newSelected);
    setQuantities(newQuantities);
    setQuantityTypes(newTypes);
  };

  const calculateAdjustedTotal = (item) => {
    if (!selected.includes(item._id)) return 0;
    const type = quantityTypes[item._id];
    const quantity = quantities[item._id] || 0;
    
    const pricePerUnit = parseFloat(item.pricePer) || 0;
    
    if (type === 'percentage') {
      const actualQty = (getRemainingQty(item) * quantity) / 100;
      return pricePerUnit * actualQty;
    } else {
      return pricePerUnit * quantity;
    }
  };

  const getTotalSelected = () => {
    return lineItems
      .filter(item => selected.includes(item._id))
      .reduce((sum, item) => sum + calculateAdjustedTotal(item), 0);
  };

  const handleCreateWorkorder = () => {
    const selectedItems = lineItems
      .filter(item => selected.includes(item._id))
      .map(item => {
        const quantity = quantityTypes[item._id] === 'percentage'
          ? (getRemainingQty(item) * quantities[item._id] / 100)
          : quantities[item._id];

        return {
          ...item,
          originalQty: item.qty,
          qty: quantity,
          totalPrice: calculateAdjustedTotal(item).toFixed(2)
        };
      });
      let total = getTotalSelected()
    onCreateWorkorder(selectedItems, total);
  };

  const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">Proposal Line Items</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={selected.length === 0}
          onClick={handleCreateWorkorder}
        >
          Create Workorder ({selected.length} items)
        </Button>
      </div>
      
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < lineItems.filter(item => getRemainingQty(item) > 0).length}
                  checked={selected.length === lineItems.filter(item => getRemainingQty(item) > 0).length && selected.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Original Qty</TableCell>
              <TableCell>Assigned Amount</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell align="right">Price/Unit</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((item) => {
              const remainingQty = getRemainingQty(item);
              const progressPercentage = getProgressPercentage(item);
              const isFullyAssigned = remainingQty === 0;

              return (
                <TableRow 
                  key={item._id}
                  hover
                  selected={selected.includes(item._id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox 
                      checked={selected.includes(item._id)}
                      onChange={() => handleSelect(item._id)}
                      disabled={isFullyAssigned}
                    />
                  </TableCell>
                  <TableCell>{item.lineItemType}</TableCell>
                  <TableCell>
                    {item.description}
                    <div className={classes.progress}>
                      <LinearProgress variant="determinate" value={progressPercentage} />
                      <Typography className={classes.remainingQuantity}>
                        Remaining: {remainingQty.toFixed(2)} {item.unit}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="right">{item.qty}</TableCell>
                  <TableCell>
                    {selected.includes(item._id) && (
                      <div className={classes.quantityCell}>
                        <FormControl className={classes.typeSelect}>
                          <Select
                            value={quantityTypes[item._id]}
                            onChange={(e) => handleQuantityTypeChange(item._id, e.target.value)}
                          >
                            <MenuItem value="quantity">Qty</MenuItem>
                            <MenuItem value="percentage">%</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          className={classes.quantityField}
                          type="number"
                          onFocus={handleFocus}
                          value={quantities[item._id]}
                          onChange={(e) => handleQuantityChange(item._id, parseFloat(e.target.value) || 0)}
                          variant="outlined"
                          size="small"
                        />
                        <Tooltip title={
                          quantityTypes[item._id] === 'percentage'
                            ? `Will create workorder with ${((remainingQty * quantities[item._id]) / 100).toFixed(2)} ${item.unit}`
                            : `Remaining quantity: ${remainingQty} ${item.unit}`
                        }>
                          <IconButton size="small">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
                  <TableCell align="right">
                    {selected.includes(item._id)
                      ? currencyFormat(calculateAdjustedTotal(item))
                      : currencyFormat(item.totalPrice)
                    }
                  </TableCell>
                </TableRow>
              );
            })}
            {selected.length > 0 && (
              <TableRow className={classes.totalRow}>
                <TableCell colSpan={7} align="right">
                  <strong>Selected Total:</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{currencyFormat(getTotalSelected())}</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProposalLineItems;
