import React, { useState } from 'react';
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Paper,
  Grid,
  Chip,
  Avatar,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  Pool as PoolIcon,
  Schedule as ScheduleIcon,
  Assessment as AssessmentIcon,
  LocalOffer as LocalOfferIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  siteCard: {
    marginBottom: theme.spacing(2),
  },
  header: {
    backgroundColor: '#516e76',
    color: theme.palette.common.white,
    '& .MuiCardHeader-subheader': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  expansionPanel: {
    marginBottom: theme.spacing(1),
  },
  serviceDate: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  readingsGrid: {
    marginTop: theme.spacing(2),
  },
  readingCard: {
    height: '100%',
  },
  readingValue: {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  readingUnit: {
    color: theme.palette.text.secondary,
  },
  chemicalsAdded: {
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  issueChip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  photos: {
    display: 'flex',
    overflowX: 'auto',
    padding: theme.spacing(1),
    '& img': {
      height: 100,
      width: 100,
      objectFit: 'cover',
      marginRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ChemicalReadingCard = ({ label, value, unit }) => {
  const classes = useStyles();
  
  if (!value) return null;
  
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Paper className={classes.readingCard} elevation={1}>
        <Box p={2}>
          <Typography variant="subtitle2" color="textSecondary">
            {label}
          </Typography>
          <Box display="flex" alignItems="baseline" mt={1}>
            <Typography className={classes.readingValue}>
              {value}
            </Typography>
            {unit && (
              <Typography className={classes.readingUnit}>
                {unit}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const PoolMaintenanceHistory = ({ sites }) => {
  const classes = useStyles();
  const [expandedSite, setExpandedSite] = useState(null);
  const [expandedService, setExpandedService] = useState(null);

  const handleSiteExpand = (siteId) => {
    setExpandedSite(expandedSite === siteId ? null : siteId);
  };

  const handleServiceExpand = (serviceId) => {
    setExpandedService(expandedService === serviceId ? null : serviceId);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className={classes.root}>
      {sites.map((site) => (
        <Card key={site.id} className={classes.siteCard}>
          <CardHeader
            className={classes.header}
            avatar={<PoolIcon />}
            title={site.name}
            subheader={`Last serviced: ${formatDate(site.lastService)}`}
            action={
              <IconButton
                onClick={() => handleSiteExpand(site.id)}
                aria-expanded={expandedSite === site.id}
              >
                <ExpandMoreIcon style={{ color: 'white' }} />
              </IconButton>
            }
          />
          <Collapse in={expandedSite === site.id}>
            <CardContent>
              {site.services.map((service) => (
                <ExpansionPanel
                  key={service.id}
                  className={classes.expansionPanel}
                  expanded={expandedService === service.id}
                  onChange={() => handleServiceExpand(service.id)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.serviceDate}>
                      <ScheduleIcon />
                      <Typography>{formatDate(service.date)}</Typography>
                      {service.issues?.length > 0 && (
                        <Chip
                          icon={<WarningIcon />}
                          label={`${service.issues.length} Issues`}
                          className={classes.issueChip}
                          size="small"
                        />
                      )}
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box width="100%">
                      {/* Initial Conditions */}
                      {service.initialConditions && (
                        <Box mb={3}>
                          <Typography variant="h6" gutterBottom>
                            Initial Conditions
                          </Typography>
                          <Grid container spacing={2}>
                            <ChemicalReadingCard
                              label="Water Clarity"
                              value={service.initialConditions.waterClarity}
                            />
                            <ChemicalReadingCard
                              label="Debris Level"
                              value={service.initialConditions.debris}
                            />
                            <ChemicalReadingCard
                              label="Equipment Status"
                              value={service.initialConditions.equipment}
                            />
                            <ChemicalReadingCard
                              label="Water Temperature"
                              value={service.initialConditions.waterTemp}
                              unit="°F"
                            />
                          </Grid>
                        </Box>
                      )}

                      {/* Chemical Readings */}
                      <Typography variant="h6" gutterBottom>
                        Chemical Readings
                      </Typography>
                      <Grid container spacing={2} className={classes.readingsGrid}>
                        {service.chemicalValues && Object.entries(service.chemicalValues)
                          .filter(([_, value]) => value)
                          .map(([key, value]) => (
                            <ChemicalReadingCard
                              key={key}
                              label={key.replace(/([A-Z])/g, ' $1').trim()}
                              value={value}
                              unit={key === 'pH' ? '' : 'ppm'}
                            />
                          ))}
                      </Grid>

                      {/* Chemicals Added */}
                      {service.chemicalsAdded?.length > 0 && (
                        <Box className={classes.chemicalsAdded}>
                          <Typography variant="h6" gutterBottom>
                            Chemicals Added
                          </Typography>
                          <Box>
                            {service.chemicalsAdded.map((chemical, index) => (
                              <Chip
                                key={index}
                                icon={<LocalOfferIcon />}
                                label={`${chemical.name}: ${chemical.amount} ${chemical.unit}`}
                                className={classes.chip}
                              />
                            ))}
                          </Box>
                        </Box>
                      )}

                      {/* Service Photos */}
                      {service.photos?.length > 0 && (
                        <Box mt={3}>
                          <Typography variant="h6" gutterBottom>
                            Service Photos
                          </Typography>
                          <div className={classes.photos}>
                            {service.photos.map((photo, index) => (
                              <img
                                key={index}
                                src={photo.path}
                                alt={`Service photo ${index + 1}`}
                              />
                            ))}
                          </div>
                        </Box>
                      )}

                      {/* Issues */}
                      {service.issues?.length > 0 && (
                        <Box mt={3}>
                          <Typography variant="h6" gutterBottom>
                            Reported Issues
                          </Typography>
                          <List>
                            {service.issues.map((issue, index) => (
                              <ListItem key={index}>
                                <ListItemText
                                  primary={issue.category}
                                  secondary={issue.description}
                                />
                                <ListItemSecondaryAction>
                                  <Chip
                                    size="small"
                                    label={issue.priority.toUpperCase()}
                                    color="secondary"
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      )}
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
};

export default PoolMaintenanceHistory;
