import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Paper
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { currencyFormat } from '../../services/common'
import PoolMaintenanceHistory from "./PoolMtcHistory";
import axios from 'axios'
import { connect } from 'react-redux'
import PoolSitesTable from './PoolSitesTable'

const sites = [
  {
    id: '1',
    name: 'Mountain View Pool',
    lastService: '2024-12-05',
    services: [
      {
        id: 'service1',
        date: '2024-12-05',
        initialConditions: {
          waterClarity: 'Crystal Clear',
          debris: 'None',
          equipment: 'All Working',
          waterTemp: '78'
        },
        chemicalValues: {
          pH: '7.4',
          freeChlorine: '3.0'
          // ... other chemical values
        },
        // ... other service details
      }
    ]
  }
];

const PoolServiceDashboard = (props) => {
  console.log('Pool Service Dashboard...', props)
  const [pools, setPools] = React.useState([])

  useEffect(() => {
    let isMounted = true
    fetchPoolServiceDetails()
    return () => {
      isMounted = false
    }
  }, [])

  const fetchPoolServiceDetails = async () => {
    console.log('Fetch Pool Service Details...')
    let url = `${props.url}/api/pools/getPoolServiceHistoryData`
    // console.log('URL to retrieve: ', url)
    try {
      const response = await axios.get(url)
      console.log('response', response)
      if (response?.data?.length) setPools(response.data)
      props.notification({
        type: 'success',
        title: 'Got Pool History',
        message: `Found Our History`
      })
    } catch (e) {
      console.log('Error getting history', e)
      props.notification({
        type: 'warning',
        title: 'Error Fetching Data',
        message: `Failed to Find Our History`
      })
    }
     
  }

  return (
    <div>
      <Paper>
        <h1>Pool Mtc Details</h1>
        <div>
          <PoolSitesTable sites={pools} />
        </div>
      </Paper>
     
      {/* <PoolSitesTable sites={sites} /> */}
      {/* <PoolMaintenanceHistory sites={sites} /> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(PoolServiceDashboard)
