import React, { useState, useMemo } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  TablePagination,
  Button,
} from '@material-ui/core';
import {
  History as HistoryIcon,
  Warning as WarningIcon,
  Pool as PoolIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  LocationOn as LocationIcon,
  Assignment as AssignmentIcon,
  TouchApp as TouchAppIcon, // for water clarity
  WbSunny as WbSunnyIcon,  // for temperature
  Layers as LayersIcon,    // for debris
  Build as BuildIcon,       // for equipment
  Opacity,
  Eco
} from '@material-ui/icons';
import ServiceHistoryModal from './ServiceHistoryModal'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  clientName: {
    fontWeight: 500,
  },
  jobsiteInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(0.5),
    },
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    '& svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    },
  },
  statusChip: {
    marginRight: theme.spacing(1),
  },
  activeChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  inactiveChip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  pendingChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  modal: {
    '& .MuiDialog-paper': {
      maxWidth: '1000px',
      width: '95vw',
    },
  },
  serviceDate: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  serviceCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  readingCard: {
    padding: theme.spacing(2),
    height: '100%',
  },
}));

const PoolSitesTable = ({ sites }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedSite, setSelectedSite] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getLastService = (workorderHistory) => {
    // console.log('Get last service from these ', workorderHistory)
    if (!workorderHistory?.length) return null;
    return workorderHistory
      .filter(wo => wo.workComplete && wo.poolMtcDetails)
      .sort((a, b) => new Date(b.work_complete_at) - new Date(a.work_complete_at))[0];
  };

  const getPendingService = (workorderHistory) => {
    if (!workorderHistory?.length) return null;
    return workorderHistory.find(wo => !wo.workComplete);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Client & Site Info</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Service</TableCell>
              <TableCell>Upon Arrival</TableCell>
              <TableCell>Chemical Levels</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sites
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((site) => {
                // console.log('240 SITE:', site)
                if (!site?.jobsiteDetails) return
                const lastService = getLastService(site.workorderHistory);
                const pendingService = getPendingService(site.workorderHistory);
                // console.log("Last service", lastService)
                if (lastService?.poolMtcDetails) {
                  // console.log('Mtc Details::', lastService.poolMtcDetails)
                }
                // console.log(site.clientName)
                return (
                  <TableRow key={site._id}>
                    <TableCell>
                      <div className={classes.jobsiteInfo}>
                        {/* <Typography className={classes.clientName}>
                          {site.clientName}
                        </Typography> */}
                        <Typography variant="body2">
                          {site.jobsiteDetails.name}
                        </Typography>
                        <Typography className={classes.address}>
                          <LocationIcon />
                          {site.jobsiteDetails.address}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Chip
                          label={site.status}
                          size="small"
                          className={classes.statusChip}
                          color={site.status === 'Active' ? 'primary' : 'default'}
                        />
                        {pendingService && (
                          <Chip
                            icon={<AssignmentIcon />}
                            label="Pending Service"
                            size="small"
                            className={classes.pendingChip}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {formatDate(lastService?.work_complete_at)}
                    </TableCell>
                    <TableCell>
                      {lastService?.poolMtcDetails?.initialConditions && (
                        <>
                          <Box display="flex" gap={1}>
                            {/* First Row */}
                            {lastService.poolMtcDetails.initialConditions.waterTemp && (
                              <Chip
                                size="small"
                                icon={<WbSunnyIcon />}
                                label={`${lastService.poolMtcDetails.initialConditions.waterTemp}°F`}
                                style={{ backgroundColor: '#e3f2fd', color: '#1976d2' }}
                              />
                            )}
                            {lastService.poolMtcDetails.initialConditions.waterClarity && (
                              <Chip
                                size="small"
                                icon={<Opacity />}
                                label={lastService.poolMtcDetails.initialConditions.waterClarity}
                                style={{
                                  backgroundColor:
                                    lastService.poolMtcDetails.initialConditions.waterClarity === 'clear' ? '#e8f5e9' : '#fff3e0',
                                  color:
                                    lastService.poolMtcDetails.initialConditions.waterClarity === 'clear' ? '#2e7d32' : '#f57c00',
                                }}
                              />
                            )}
                          </Box>
                          <Box display="flex" gap={1} mt={1}>
                            {/* Second Row */}
                            {lastService.poolMtcDetails.initialConditions.debris && (
                              <Chip
                                size="small"
                                icon={<Eco />}
                                label={lastService.poolMtcDetails.initialConditions.debris}
                                style={{
                                  backgroundColor: '#fafafa',
                                  color: '#424242',
                                }}
                              />
                            )}
                            {lastService.poolMtcDetails.initialConditions.equipment && (
                              <Chip
                                size="small"
                                icon={<BuildIcon />}
                                label={lastService.poolMtcDetails.initialConditions.equipment}
                                style={{
                                  backgroundColor:
                                    lastService.poolMtcDetails.initialConditions.equipment === 'working' ? '#e8f5e9' : '#ffebee',
                                  color: lastService.poolMtcDetails.initialConditions.equipment === 'working' ? '#2e7d32' : '#c62828',
                                }}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </TableCell>


                    <TableCell>
                      {lastService?.poolMtcDetails?.chemicalValues && (
                        <>
                          {lastService.poolMtcDetails.chemicalValues.pH && (
                            <Chip
                              size="small"
                              label={`pH: ${lastService.poolMtcDetails.chemicalValues.pH}`}
                              style={{ marginRight: 8 }}
                            />
                          )}
                          {lastService.poolMtcDetails.chemicalValues.freeChlorine && (
                            <Chip
                              size="small"
                              label={`Cl: ${lastService.poolMtcDetails.chemicalValues.freeChlorine} ppm`}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => setSelectedSite(site)}
                        size="small"
                        title="View Service History"
                      >
                        <HistoryIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={sites.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
      {selectedSite && (
        <ServiceHistoryModal
          open={Boolean(selectedSite)}
          onClose={() => setSelectedSite(null)}
          jobsite={selectedSite.jobsiteDetails}
          workorderHistory={selectedSite.workorderHistory}
        />
      )}
    </div>
  );
};

export default PoolSitesTable;
