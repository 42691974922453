import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import moment from 'moment'
import SignatureBox from '../Proposals/SignatureBox'
// import LineItemDetails from './OnlineProposalLineItems'
import ESignatureModal from '../Proposals/ESignatureModal'
import ChoosePlanModal from './ChoosePlanModal'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    backgroundColor: '#eee'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
      textAlign: 'center'
    },
    // margin: 20
  },
  sigBox: {
    // backgroundColor: 'green',
    margin: 20,
    width: '100%',
    textAlign: 'center'
  },
  headerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  footerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  disclosureArea: {
    fontSize: '0.8rem',
    color: '#bbb',
    textAlign: 'center'
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      // textAlign: 'center'
      fontSize: 24,
      // color: 'green'
    }
  },
  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  }
}));

function ViewOnlineProposal(props) {
// console.log('View Online Proposal ', props)
  const [state, setState] = React.useState({
    counter: 0
  })
  const [logoURL, setLogoURL] = React.useState(null)
  const [contact, setContact] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [showESigModal, setShowESigModal] = React.useState(false)
  const [showChoosePlanModal, setShowChoosePlanModal] = React.useState(false)
  const [plan, setPlan] = React.useState('Advantage')
  const [contract, setContract] = React.useState({})

  const generatePDF = (agreementId) => {
  console.log('Generate PDF!', agreementId)
    axios({
      method: 'get',
      url:  `${props.url}/api/pools/generatePDF?id=${agreementId}`
    }).then(response => {
    // console.log('Got the pDF!!', response.data)
      setPDF(response.data)
    })
  }

  
  React.useEffect(() => {
    let isMounted = true

    const fetchEstimate = async (estimateId) => {
    console.log('Fetch Estimate:', estimateId)
    if (estimateId && estimateId._id) estimateId = estimateId._id
        let url = `${props.url}/api/pools/getOnlineProposalDetails?id=${estimateId}`
      // console.log('URL to retrieve: ', url)
        const response = await axios.get(url)
            console.log('got our contract details!!', response)
        if (response && response.data && response.data._id && isMounted) {
        // console.log('BID: ', response.data)
          setContract(response.data)
          if (response.data && response.data.contact && response.data.contact._id) setContact(response.data.contact)
          generatePDF(response.data._id)
          // setProposal(response.data.estimate)
          // setContact(response.data.contact)
          // let url = response.data.estimate.entity._id
          // let logo = '/' + url + '_Logo_W_BestOfValleyChips.png'
          // // <img src="/Logo_W_BestOfValleyCHips.png" />
          // // console.log('URL: ', url)
          // // console.log('Logo: ', logo)
          // setLogoURL(logo)
          // let est = response.data.estimatesetShowPDF
          // if (est && est.url) {
          //   await axios.get(est.url, {
          //     withCredentials: false,
          //     headers: {
          //       'Content-Type': 'application/octet-stream',
          //       'Accept': 'application/pdf, application-octet-streatm'
          //     },
          //     responseType: 'blob'
          //   }).then((response) => {
                // setPDF(response.data)
          //     })
          // }
          // setLogoURL(`../../../${url}_Logo.png`)

      }

    }

    if (props.match && props.match.params && props.match.params.bidId) {
      // console.log(props.match.params)
      console.log('Fetch the Pool Proposal!!', props)
        fetchEstimate(props.match.params.bidId)
    }
    // fetchEstimate()
    return () => {
      isMounted = false
    }
  }, [])

  const classes = useStyles();

 const handleOpenESigModal = () => {
    
    setShowESigModal(true)
  }

  const handleChoosePlan = () => {
  // console.log('Updated it...')
    setShowChoosePlanModal(true)
  }

  const handleCloseESigModal = () => {
    setShowESigModal(false)
  }

  const handleEsignatureSubmission = (name) => {
    // console.log('Handle esignature: ', name)
    setShowESigModal(false)
    let data = {
      contractId: contract._id,
      // contactId: contact._id,
      plan: plan,
      name: name
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/pools/approveBid`,
      data: data,
    }).then(response => {
    // console.log('Approved Pool Mtc', response.data)
      let newContract = contract
    // console.log('New Contract', newContract)
        newContract.accepted = true
        setContract( newContract )
      // console.log('Set the contract:', newContract)
        // setState({...state, counter: state.counter++})
      props.notification({
        type: 'success',
        title: 'Proposal Accepted!',
        message: 'Thank you for accepting this proposal, we will be in touch soon.'
      })
    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Approve Estimate!',
        message: err.message
      })
      // return callback(err)
    })

  }

  const handleSelection = (planChosen) => {
  // console.log('Plan Selected')
    setPlan(planChosen)
    setShowChoosePlanModal(false)
    setShowESigModal(true)
  }

  const closePlanModal = () => { setShowChoosePlanModal(false) }

  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
      <ChoosePlanModal open={showChoosePlanModal} contract={contract} makeSelection={handleSelection} handleClose={closePlanModal} />
       <ESignatureModal open={showESigModal} contact={contact} handleClose={handleCloseESigModal} submit={handleEsignatureSubmission} />
        <Grid container>
          
          <Grid item lg={12} xs={12} className={classes.headerImages}>
        
            <img src={`${process.env.PUBLIC_URL}/poolHeader.png`} width="100%" />
            {/* <img src="/Logo_W_BestOfValleyCHips.png" /> */}
          </Grid>

          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.giantText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            BEAUTIFUL
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.subText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            OUTDOOR LIVING SPACES THAT BENEFIT
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.squigglyText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            The Mind, Body, And Soul
          </Grid>

          
            {
              contract.accepted && <Grid item lg={12} xs={12} sm={12} md={12}  className={classes.nameText}>You May View The Signed Document Below:</Grid>
            }
          
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.signUpText}>
            <GetStartedButton contract={contract} handleChoosePlan={handleChoosePlan} />
            
          </Grid>
         
          {
            // <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="860px" />
            (pdf && pdf.length) ? (
              <object style={{padding: '15px', minHeight: '960px'}} aria-label="PDF" data={ pdf ? pdf : null } width="100%" height="960px" type="application/pdf" />
            ) : (
              <React.Fragment>
                <Grid item lg={12} xs={12} sm={12} md={12} >
                 Loading Proposal Now
            
                </Grid>
              </React.Fragment>
            )
          }
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.signUpText}>
                  
              <GetStartedButton contract={contract} handleChoosePlan={handleChoosePlan} />
          </Grid>
          {/* <GetStartedButton contract={contract} handleChoosePlan={handleChoosePlan} /> */}
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.footerImages}>
            <img src={`${process.env.PUBLIC_URL}/poolFooter.png`} width="100%"/>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            This proposal was intended to be viewed by {(contact && contact._id) ? `${contact.firstName} ${contact.lastName}` : ''}, if you were sent this link inadvertantly, please close the browser now.
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            Copyright { contract.entity ? contract.entity.name : ''} { new Date().getFullYear()}, All Rights Reserved.
          </Grid>
        </Grid>
     </Paper>
    </div>
  );
}

const GetStartedButton = (props) => {
  const agreement = props.contract
  if (agreement && agreement.accepted) {
    return null
  } else {
    return (
      <Button variant="contained" color="primary" onClick={props.handleChoosePlan}>
      Let's Get Started!</Button>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewOnlineProposal)

