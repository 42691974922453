import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover
  },
  summaryCard: {
    marginBottom: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  subtotalSection: {
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const WorkorderLineItems = ({ workorder }) => {
  const classes = useStyles();

  const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  // Calculate totals
  const getMaterialsTotal = () => {
    return workorder.lineItems
      .filter(item => item.lineItemType === 'materials')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getLaborTotal = () => {
    return workorder.lineItems
      .filter(item => item.lineItemType === 'labor')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getSubtotal = () => {
    return workorder.lineItems.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  return (
    <div className={classes.root}>
      {/* Summary Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Materials" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getMaterialsTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder.lineItems.filter(item => item.lineItemType === 'materials').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Labor" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getLaborTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder.lineItems.filter(item => item.lineItemType === 'labor').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Total" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getSubtotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder.lineItems.length} total items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Line Items Table */}
      <Card>
        <CardHeader title="Line Items" />
        <CardContent>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Price/Unit</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workorder.lineItems.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Chip
                        label={item.lineItemType}
                        color={item.lineItemType === 'materials' ? 'primary' : 'secondary'}
                        size="small"
                        className={classes.chip}
                      />
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">{parseFloat(item.qty).toFixed(2)}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
                    <TableCell align="right">{currencyFormat(item.totalPrice)}</TableCell>
                  </TableRow>
                ))}
                
                {/* Subtotal Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal())}</strong>
                  </TableCell>
                </TableRow>

                {/* Tax Row - if tax exists */}
                {workorder.taxAmount > 0 && (
                  <TableRow className={classes.totalRow}>
                    <TableCell colSpan={4} />
                    <TableCell align="right">Tax:</TableCell>
                    <TableCell align="right">{currencyFormat(workorder.taxAmount)}</TableCell>
                  </TableRow>
                )}

                {/* Grand Total Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Total:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal() + (workorder.taxAmount || 0))}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default WorkorderLineItems;
