import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  Chip,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Print as PrintIcon,
  MonetizationOn as TaxIcon,
  Assignment as PunchlistIcon,
  PhotoCamera as PhotoIcon,
  CloudDone as CompleteIcon,
  ShoppingCart as SuppliesIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { handleFocus } from '../../services/common';
import WorkorderLineItems from './WorkorderLineItems';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  workorderNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  statusChip: {
    marginLeft: theme.spacing(2),
  },
  scrollContainer: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  topControls: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputField: {
    flex: 1,
  },
  dateField: {
    width: 200,
  },
  priceField: {
    width: 150,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    '& .MuiCardHeader-title': {
      fontSize: '1rem',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  actionsContainer: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  // Make text fields more compact
  textField: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '8px 14px',
      },
    },
    '& .MuiInputBase-inputMultiline': {
      minHeight: '40px !important',
      padding: '8px 14px',
    },
  },
}));

const WorkorderDetailsDashboard = ({
  workorder,
  onPrint,
  onEditTaxes,
  onViewPunchlist,
  onViewPhotos,
  onComplete,
  onExportSupplies,
  onChange,
  editedData,
  punchlistItems = [],
  serviceTypes = [], // You'll need to pass these in
  crews = [], // You'll need to pass these in
}) => {
  const classes = useStyles();

  const handleFieldChange = (field, value) => {
    onChange({
      ...editedData,
      [field]: value,
    });
  };

  const getStatusColor = () => {
    if (workorder.completedDate) return 'primary';
    if (workorder.scheduledDate && moment(workorder.scheduledDate).isAfter(moment())) return 'default';
    return 'secondary';
  };

  const getStatusText = () => {
    if (workorder.completedDate) return 'Completed';
    if (workorder.invoiceDate) return 'Invoiced';
    if (workorder.scheduledDate) return 'Scheduled';
    return 'Pending';
  };

  const punchlistItemsForThisWO = punchlistItems.filter(item => item.workorder === workorder._id);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div>
            <Typography variant="h5" className={classes.workorderNumber}>
              Workorder #{workorder.number}
              <Chip
                label={getStatusText()}
                color={getStatusColor()}
                className={classes.statusChip}
                size="small"
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Created: {moment(workorder.created_at).format('MM/DD/YYYY')}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.scrollContainer}>
        {/* Top row of inputs */}
        <div className={classes.topControls}>
          <Autocomplete
            className={classes.inputField}
            size="small"
            options={serviceTypes}
            value={editedData.serviceName || null}
            onChange={(_, newValue) => handleFieldChange('serviceName', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Service Type"
                size="small"
              />
            )}
          />
          <Autocomplete
            className={classes.inputField}
            size="small"
            options={crews}
            value={editedData.crewName || null}
            onChange={(_, newValue) => handleFieldChange('crewName', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Assigned Crew"
                size="small"
              />
            )}
          />
          <TextField
            className={classes.dateField}
            size="small"
            variant="outlined"
            type="date"
            label="Scheduled Date"
            value={moment(editedData.scheduledDate).format('YYYY-MM-DD')}
            onChange={(e) => handleFieldChange('scheduledDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.priceField}
            size="small"
            variant="outlined"
            label="Price"
            disabled={workorder?.estimate}
            value={editedData.price || ''}
            onChange={(e) => handleFieldChange('price', e.target.value)}
          />
        </div>

        {/* Notes Section */}
        <Card className={classes.card}>
          <CardHeader title="Notes & Comments" className={classes.cardHeader} />
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  rows={2}
                  label="Service Notes"
                  value={editedData.serviceNotes || ''}
                  onChange={(e) => handleFieldChange('serviceNotes', e.target.value)}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  rows={2}
                  disabled
                  label="Field Notes"
                  value={editedData.fieldNotes || ''}
                  onChange={(e) => handleFieldChange('fieldNotes', e.target.value)}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Line Items */}
        <Card className={classes.card}>
          <CardHeader title="Line Items" className={classes.cardHeader} />
          <CardContent className={classes.cardContent}>
            <WorkorderLineItems workorder={workorder} />
          </CardContent>
        </Card>
      </div>

      <div className={classes.actionsWrapper}>
        <div className={classes.actionsContainer}>
          <Button
            size="small"
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={onPrint}
          >
            Print
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<TaxIcon />}
            onClick={onEditTaxes}
          >
            Taxes (${workorder.taxAmount})
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<PunchlistIcon />}
            onClick={() => onViewPunchlist(workorder)}
          >
            Punchlist ({punchlistItemsForThisWO?.length || 0})
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<PhotoIcon />}
            onClick={onViewPhotos}
          >
            Photos
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<SuppliesIcon />}
            onClick={onExportSupplies}
          >
            Supplies ({workorder.supplies?.length || 0})
          </Button>
          {!workorder.completedDate && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<CompleteIcon />}
              onClick={onComplete}
            >
              Complete & Invoice
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default WorkorderDetailsDashboard;
