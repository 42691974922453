import React, { useState } from 'react';
import {
  makeStyles,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Collapse,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  History as HistoryIcon,
  Warning as WarningIcon,
  Pool as PoolIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  LocationOn as LocationIcon,
  Assignment as AssignmentIcon,
  Visibility as VisibilityIcon,
  ExpandMore as ExpandMoreIcon,
  CalendarToday as CalendarIcon,
  Opacity as WaterIcon,
  Timeline as TimelineIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiDialog-paper': {
      maxWidth: '1200px',
      width: '95vw',
      height: '90vh',
    },
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  sidebar: {
    width: 320,
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('width'),
    '&.expanded': {
      width: 400,
    },
  },
  sidebarHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  historyList: {
    overflowY: 'auto',
    flex: 1,
    padding: 0,
  },
  serviceItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
      },
    },
    transition: theme.transitions.create(['background-color', 'border-left']),
  },
  serviceDate: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(3),
  },
  readingsGrid: {
    marginTop: theme.spacing(2),
  },
  readingCard: {
    height: '100%',
    transition: theme.transitions.create('transform'),
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  },
  issueChip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    margin: theme.spacing(0, 1),
  },
  expandButton: {
    transition: theme.transitions.create('transform'),
    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  },
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.palette.text.secondary,
    '& svg': {
      fontSize: 48,
      marginBottom: theme.spacing(2),
      opacity: 0.5,
    },
  },
  summaryChips: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  readingValue: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  readingLabel: {
    fontSize: '1.6rem',
    fontWeight: 700,
    color: '#516e76',
  },
  subLabel: {
    // fontSize: 'rem',
    fontWeight: 600,
    color: '#516e76',
  }
}));

const ServiceHistoryModal = ({ open, onClose, jobsite, workorderHistory }) => {
  const classes = useStyles();
  const [selectedService, setSelectedService] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  React.useEffect(() => {
    if (workorderHistory?.length && !selectedService) {
      console.log('set our selected service', workorderHistory)
      setSelectedService(workorderHistory[workorderHistory.length - 1])
    }
  }, [])

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const sortedHistory = [...workorderHistory]
    .filter(wo => wo.poolMtcDetails)
    .sort((a, b) => new Date(b.work_complete_at) - new Date(a.work_complete_at));

  const getServiceSummary = (service) => {
    if (!service?.poolMtcDetails?.chemicalValues) return [];
    const { chemicalValues } = service.poolMtcDetails;
    const summary = [];
    if (chemicalValues.pH) summary.push({ label: 'pH', value: chemicalValues.pH });
    if (chemicalValues.freeChlorine) summary.push({ label: 'Chlorine', value: `${chemicalValues.freeChlorine} ppm` });
    return summary;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.modal}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <PoolIcon style={{ marginRight: 8, color: '#2196f3' }} />
            <Typography variant="h6">{jobsite.name}</Typography>
          </Box>
          <Typography variant="subtitle2" color="textSecondary">
            AG ID: {jobsite.agId}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <LocationIcon fontSize="small" color="action" />
          <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: 4 }}>
            {jobsite.address}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <div className={classes.contentContainer}>
          {/* Service History Sidebar */}
          <div className={`${classes.sidebar} ${isExpanded ? 'expanded' : ''}`}>
            <div className={classes.sidebarHeader}>
              <Typography variant="subtitle1">
                <HistoryIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'middle' }} />
                Service History
              </Typography>
              <IconButton
                size="small"
                onClick={() => setIsExpanded(!isExpanded)}
                className={`${classes.expandButton} ${isExpanded ? 'expanded' : ''}`}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>

            <List className={classes.historyList}>
              {sortedHistory.map((service, index) => (
                <React.Fragment key={service._id || index}>
                  <ListItem
                    button
                    onClick={() => setSelectedService(service)}
                    className={`${classes.serviceItem} ${selectedService?._id === service._id ? 'selected' : ''}`}
                  >
                    <ListItemText
                      primary={
                        <Box className={classes.serviceDate}>
                          <CalendarIcon fontSize="small" />
                          <Typography>{formatDate(service.work_complete_at)} (#{service.number})</Typography>
                          {service.poolMtcDetails?.issues?.length > 0 && (
                            <Chip
                              icon={<WarningIcon />}
                              label={service.poolMtcDetails.issues.length}
                              size="small"
                              className={classes.issueChip}
                            />
                          )}
                        </Box>
                      }
                      secondary={
                        <Box className={classes.summaryChips}>
                          {getServiceSummary(service).map((reading, idx) => (
                            <Chip
                              key={idx}
                              icon={<WaterIcon />}
                              label={`${reading.label}: ${reading.value}`}
                              size="small"
                              variant="outlined"
                              color="primary"
                            />
                          ))}
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < sortedHistory.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </div>

          {/* Main Content Area */}
          <div className={classes.mainContent}>
            {selectedService ? (
              <div>
                <Box mb={3}>
                  <Typography variant="h5" gutterBottom>
                    Service Details - {formatDate(selectedService.work_complete_at)}
                  </Typography>
                
                {selectedService.poolMtcDetails?.initialConditions && (
                  <Box mb={3}>
                    <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                      Initial Conditions
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(selectedService.poolMtcDetails.initialConditions)
                        .filter(([key, value]) => value && key !== 'notes')
                        .map(([key, value]) => (
                          <Grid item xs={6} sm={3} key={key}>
                              <Card className={classes.readingCard}>
                              <CardContent>
                                <Typography color="textSecondary" className={classes.subLabel} gutterBottom>
                                {key === 'waterTemp' ? 'Water Temp' : 
                                 key.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                                }  
                                </Typography>
                                <Typography className={classes.readingValue}>
                                {value.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                {key === 'waterTemp' ? '°F' : ''}
                                </Typography>
                              </CardContent>
                            </Card>

          
                          </Grid>
                      ))}
                    </Grid>
                    {selectedService.poolMtcDetails.initialConditions.notes && (
                      <Box mt={2}>
                        <Paper style={{ padding: theme.spacing(2) }}>
                          <Typography variant="body2" color="textSecondary">
                            Additional Notes
                          </Typography>
                          <Typography variant="body1">
                            {selectedService.poolMtcDetails.initialConditions.notes}
                          </Typography>
                        </Paper>
                      </Box>
                    )}
                  </Box>
                )}

                  {selectedService.poolMtcDetails?.chemicalValues && (
                    <Grid container spacing={3} className={classes.readingsGrid}>
                      {Object.entries(selectedService.poolMtcDetails.chemicalValues)
                        .filter(([_, value]) => value)
                        .map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Card className={classes.readingCard}>
                              <CardContent>
                                <Typography color="textSecondary" className={classes.subLabel} gutterBottom>
                                  { key === 'pH' ? 'pH' : key.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                </Typography>
                                <Typography className={classes.readingValue}>
                                  {value}{key === 'pH' ? '' : ' ppm'}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                   
                  {/* Equipment Readings Section */}
                  {selectedService.poolMtcDetails?.equipmentReadings && (
                    <Box mb={3}>
                      <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                        Equipment Readings
                      </Typography>
                      <Grid container spacing={3} className={classes.readingsGrid}>
                        {Object.entries(selectedService.poolMtcDetails.equipmentReadings)
                          .filter(([_, value]) => value)
                          .map(([key, value]) => (
                            <Grid item xs={12} sm={6} md={4} key={key}>
                              <Card className={classes.readingCard}>
                                <CardContent>
                                  <Typography color="textSecondary" className={classes.subLabel} gutterBottom>
                                    {key.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                  </Typography>
                                  <Typography className={classes.readingValue}>
                                    {value}
                                    {key === 'pumpPressure' ? ' PSI' : 
                                    key === 'pumpGPM' ? ' GPM' :
                                    key === 'heaterTemp' ? '°F' :
                                    key === 'saltLevel' ? ' ppm' : ''}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                      {selectedService.poolMtcDetails.equipmentNotes && (
                        <Box mt={2}>
                          <Card>
                            <CardContent>
                              <Typography color="textSecondary" className={classes.subLabel} gutterBottom>
                                Equipment Notes
                              </Typography>
                              <Typography variant="body1">
                                {selectedService.poolMtcDetails.equipmentNotes}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Chemicals Added Section */}
                  {selectedService.poolMtcDetails?.chemicalsAdded?.length > 0 && (
                    <Box mb={3}>
                      <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                        Chemicals Added
                      </Typography>
                      <Grid container spacing={3} className={classes.readingsGrid}>
                        {selectedService.poolMtcDetails.chemicalsAdded.map((chemical, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card className={classes.readingCard}>
                              <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                  <Box>
                                    <Typography color="textSecondary" className={classes.subLabel} gutterBottom>
                                      {chemical.name}
                                    </Typography>
                                    <Typography className={classes.readingValue}>
                                      {chemical.amount} {chemical.unit}
                                    </Typography>
                                  </Box>
                                  <Typography variant="caption" color="textSecondary">
                                    {new Date(chemical.timestamp).toLocaleTimeString([], {
                                      hour: '2-digit',
                                      minute: '2-digit'
                                    })}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}

                  {/* Checklist Items Section */}
                  {selectedService.poolMtcDetails?.checklist?.length > 0 && (
                    <Box mb={3}>
                      <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                        Completed Tasks
                      </Typography>
                      <Grid container spacing={3} className={classes.readingsGrid}>
                        {selectedService.poolMtcDetails.checklist.map((item, index) => (
                          <Grid item xs={12} sm={6} key={index}>
                            <Card className={classes.readingCard}>
                              <CardContent>
                                <Box display="flex" alignItems="center">
                                  {item.checked ? (
                                    <CheckCircleIcon style={{ color: '#4caf50', marginRight: 8 }} />
                                  ) : (
                                    <ErrorIcon style={{ color: '#f44336', marginRight: 8 }} />
                                  )}
                                  <Typography variant="body1">
                                    {item.label}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}

                  {/* Photos Section */}
                  {selectedService.poolMtcDetails?.photos?.length > 0 && (
                    <Box mb={3}>
                      <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                        Service Photos
                      </Typography>
                      <Grid container spacing={3} className={classes.readingsGrid}>
                        {selectedService.poolMtcDetails.photos.map((photo, index) => (
                          <Grid item xs={6} sm={4} md={3} key={index}>
                            <Card className={classes.readingCard}>
                              <CardContent>
                                <img
                                  src={photo.path}
                                  alt={`Service photo ${index + 1}`}
                                  style={{
                                    width: '100%',
                                    height: 200,
                                    objectFit: 'cover',
                                    borderRadius: theme.shape.borderRadius,
                                  }}
                                />
                                {selectedService.poolMtcDetails.selectedPhotos?.includes(photo._id) && (
                                  <Box mt={1}>
                                    <Chip
                                      size="small"
                                      label="Selected for Customer"
                                      color="primary"
                                    />
                                  </Box>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}

                  {/* Issues Section */}
                  {selectedService.poolMtcDetails?.issues?.length > 0 && (
                    <Box mb={3}>
                      <Typography variant="subtitle1" className={classes.readingLabel} gutterBottom>
                        Reported Issues
                      </Typography>
                      <Grid container spacing={3} className={classes.readingsGrid}>
                        {selectedService.poolMtcDetails.issues.map((issue, index) => (
                          <Grid item xs={12} key={index}>
                            <Card className={classes.readingCard}>
                              <CardContent>
                                <Box>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Typography variant="h6" style={{ color: '#e74c3c' }}>
                                      {issue.category.replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </Typography>
                                    <Chip
                                      size="small"
                                      label={issue.priority.toUpperCase()}
                                      color="secondary"
                                    />
                                  </Box>
                                  <Typography variant="body1" paragraph>
                                    {issue.description}
                                  </Typography>
                                  <Typography variant="caption" color="textSecondary">
                                    Reported by: {issue.reportedBy} at {new Date(issue.timestamp).toLocaleString()}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </div>
            ) : (
              <div className={classes.placeholder}>
                <VisibilityIcon />
                <Typography variant="subtitle1">
                  Select a service visit to view details
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceHistoryModal;
